import {makeAutoObservable} from "mobx";
import {api, catchApi, RegistrationNotificationDto, RegistrationNotificationDtoNotificationTypeEnum} from "../api";

const mvrFrontendTypes = [
    RegistrationNotificationDtoNotificationTypeEnum.INFO,
    RegistrationNotificationDtoNotificationTypeEnum.ERROR,
    RegistrationNotificationDtoNotificationTypeEnum.SUCCESS,
    RegistrationNotificationDtoNotificationTypeEnum.WARNING,
    RegistrationNotificationDtoNotificationTypeEnum.VERIMI_LOCAL_IDENT_IOS_CODE,
    RegistrationNotificationDtoNotificationTypeEnum.VERIMI_LOCAL_IDENT_WEB_URL
]

class RegistrationNotificationStore {
    get data(): RegistrationNotificationDto[] {
        return this._data;
    }

    set data(value: RegistrationNotificationDto[]) {
        this._data = value;
    }
    get id(): string | undefined {
        return this._id;
    }

    set id(value: string | undefined) {
        this._id = value;
    }
    constructor() {
        makeAutoObservable(this)
    }

    private _id: string | undefined
    private _data: RegistrationNotificationDto[] = []

    async load(idParam?:string) {
        if (idParam) {
            this.id = idParam
        }
        const id = this.id
        if (!id) {
            return
        }
        await catchApi(async () => {
            const response = await api.v1.getRegistrationNotifications(id)
            this.data = response.data.filter(e => e.notificationType && mvrFrontendTypes.indexOf(e.notificationType) !== -1) || []
        })
    }
}

const registrationNotificationStore = new RegistrationNotificationStore()

export {
    registrationNotificationStore
}
