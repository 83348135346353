import {observer} from "mobx-react";
import React from "react";
import {registrationStore, registrationValidationStore} from "../../../../store";
import {CitySelect, FieldInput} from "../../../ui";

function formatIban(val: string | undefined) {
    return (val || '').replace(/[^\dA-Z]/g, '')
        .replace(/(.{4})/g, '$1 ').trim()
}


interface IbanRule {
    length: number
    exp: RegExp
}
const ibanRules = new Map<String, IbanRule>()
ibanRules.set('AD', {length: 24, exp: new RegExp('AD\\d{10}[A-Z0-9]{12}')})
ibanRules.set('AE', {length: 23, exp: new RegExp('AE\\d{21}')})
ibanRules.set('AL', {length: 28, exp: new RegExp('AL\\d{10}[A-Z0-9]{16}')})
ibanRules.set('AT', {length: 20, exp: new RegExp('AT\\d{18}')})
ibanRules.set('AZ', {length: 28, exp: new RegExp('AZ\\d{2}[A-Z]{4}[A-Z0-9]{20}')})
ibanRules.set('BA', {length: 20, exp: new RegExp('BA\\d{18}')})
ibanRules.set('BE', {length: 16, exp: new RegExp('BE\\d{14}')})
ibanRules.set('BG', {length: 22, exp: new RegExp('BG\\d{2}[A-Z]{4}\\d{6}[A-Z0-9]{8}')})
ibanRules.set('BH', {length: 22, exp: new RegExp('BH\\d{2}[A-Z]{4}[A-Z0-9]{14}')})
ibanRules.set('BR', {length: 29, exp: new RegExp('BR\\d{25}[A-Z]{1}[A-Z0-9]{1}')})
ibanRules.set('BY', {length: 28, exp: new RegExp('BY\\d{2}[A-Z0-9]{4}\\d{4}[A-Z0-9]{16}')})
ibanRules.set('CH', {length: 21, exp: new RegExp('CH\\d{7}[A-Z0-9]{12}')})
ibanRules.set('CR', {length: 22, exp: new RegExp('CR\\d{20}')})
ibanRules.set('CY', {length: 28, exp: new RegExp('CY\\d{10}[A-Z0-9]{16}')})
ibanRules.set('CZ', {length: 24, exp: new RegExp('CZ\\d{22}')})
ibanRules.set('DE', {length: 22, exp: new RegExp('DE\\d{20}')})
ibanRules.set('DK', {length: 18, exp: new RegExp('DK\\d{16}')})
ibanRules.set('DO', {length: 28, exp: new RegExp('DO\\d{2}[A-Z0-9]{4}\\d{20}')})
ibanRules.set('EE', {length: 20, exp: new RegExp('EE\\d{18}')})
ibanRules.set('EG', {length: 29, exp: new RegExp('EG\\d{27}')})
ibanRules.set('ES', {length: 24, exp: new RegExp('ES\\d{22}')})
ibanRules.set('FI', {length: 18, exp: new RegExp('FI\\d{16}')})
ibanRules.set('FO', {length: 18, exp: new RegExp('FO\\d{16}')})
ibanRules.set('FR', {length: 27, exp: new RegExp('FR\\d{12}[A-Z0-9]{11}\\d{2}')})
ibanRules.set('GB', {length: 22, exp: new RegExp('GB\\d{2}[A-Z]{4}\\d{14}')})
ibanRules.set('GE', {length: 22, exp: new RegExp('GE\\d{2}[A-Z]{2}\\d{16}')})
ibanRules.set('GI', {length: 23, exp: new RegExp('GI\\d{2}[A-Z]{4}[A-Z0-9]{15}')})
ibanRules.set('GL', {length: 18, exp: new RegExp('GL\\d{16}')})
ibanRules.set('GR', {length: 27, exp: new RegExp('GR\\d{9}[A-Z0-9]{16}')})
ibanRules.set('GT', {length: 28, exp: new RegExp('GT\\d{2}[A-Z0-9]{24}')})
ibanRules.set('HR', {length: 21, exp: new RegExp('HR\\d{19}')})
ibanRules.set('HU', {length: 28, exp: new RegExp('HU\\d{26}')})
ibanRules.set('IE', {length: 22, exp: new RegExp('IE\\d{2}[A-Z]{4}\\d{14}')})
ibanRules.set('IL', {length: 23, exp: new RegExp('IL\\d{21}')})
ibanRules.set('IQ', {length: 23, exp: new RegExp('IQ\\d{2}[A-Z]{4}\\d{15}')})
ibanRules.set('IS', {length: 26, exp: new RegExp('IS\\d{24}')})
ibanRules.set('IT', {length: 27, exp: new RegExp('IT\\d{2}[A-Z]{1}\\d{10}[A-Z0-9]{12}')})
ibanRules.set('JO', {length: 30, exp: new RegExp('JO\\d{2}[A-Z]{4}\\d{4}[A-Z0-9]{18}')})
ibanRules.set('KW', {length: 30, exp: new RegExp('KW\\d{2}[A-Z]{4}[A-Z0-9]{22}')})
ibanRules.set('KZ', {length: 20, exp: new RegExp('KZ\\d{5}[A-Z0-9]{13}')})
ibanRules.set('LB', {length: 28, exp: new RegExp('LB\\d{6}[A-Z0-9]{20}')})
ibanRules.set('LC', {length: 32, exp: new RegExp('LC\\d{2}[A-Z]{4}[A-Z0-9]{24}')})
ibanRules.set('LI', {length: 21, exp: new RegExp('LI\\d{7}[A-Z0-9]{12}')})
ibanRules.set('LT', {length: 20, exp: new RegExp('LT\\d{18}')})
ibanRules.set('LU', {length: 20, exp: new RegExp('LU\\d{5}[A-Z0-9]{13}')})
ibanRules.set('LV', {length: 21, exp: new RegExp('LV\\d{2}[A-Z]{4}[A-Z0-9]{13}')})
ibanRules.set('MC', {length: 27, exp: new RegExp('MC\\d{12}[A-Z0-9]{11}\\d{2}')})
ibanRules.set('MD', {length: 24, exp: new RegExp('MD\\d{2}[A-Z0-9]{20}')})
ibanRules.set('ME', {length: 22, exp: new RegExp('ME\\d{20}')})
ibanRules.set('MK', {length: 19, exp: new RegExp('MK\\d{5}[A-Z0-9]{10}\\d{2}')})
ibanRules.set('MR', {length: 27, exp: new RegExp('MR\\d{25}')})
ibanRules.set('MT', {length: 31, exp: new RegExp('MT\\d{2}[A-Z]{4}\\d{5}[A-Z0-9]{18}')})
ibanRules.set('MU', {length: 30, exp: new RegExp('MU\\d{2}[A-Z]{4}\\d{19}[A-Z]{3}')})
ibanRules.set('NL', {length: 18, exp: new RegExp('NL\\d{2}[A-Z]{4}\\d{10}')})
ibanRules.set('NO', {length: 15, exp: new RegExp('NO\\d{13}')})
ibanRules.set('PK', {length: 24, exp: new RegExp('PK\\d{2}[A-Z]{4}[A-Z0-9]{16}')})
ibanRules.set('PL', {length: 28, exp: new RegExp('PL\\d{26}')})
ibanRules.set('PS', {length: 29, exp: new RegExp('PS\\d{2}[A-Z]{4}[A-Z0-9]{21}')})
ibanRules.set('PT', {length: 25, exp: new RegExp('PT\\d{23}')})
ibanRules.set('QA', {length: 29, exp: new RegExp('QA\\d{2}[A-Z]{4}[A-Z0-9]{21}')})
ibanRules.set('RO', {length: 24, exp: new RegExp('RO\\d{2}[A-Z]{4}[A-Z0-9]{16}')})
ibanRules.set('RS', {length: 22, exp: new RegExp('RS\\d{20}')})
ibanRules.set('SA', {length: 24, exp: new RegExp('SA\\d{4}[A-Z0-9]{18}')})
ibanRules.set('SC', {length: 31, exp: new RegExp('SC\\d{2}[A-Z]{4}\\d{20}[A-Z]{3}')})
ibanRules.set('SE', {length: 24, exp: new RegExp('SE\\d{22}')})
ibanRules.set('SI', {length: 19, exp: new RegExp('SI\\d{17}')})
ibanRules.set('SK', {length: 24, exp: new RegExp('SK\\d{22}')})
ibanRules.set('SM', {length: 27, exp: new RegExp('SM\\d{2}[A-Z]{1}\\d{10}[A-Z0-9]{12}')})
ibanRules.set('ST', {length: 25, exp: new RegExp('ST\\d{23}')})
ibanRules.set('SV', {length: 28, exp: new RegExp('SV\\d{2}[A-Z]{4}\\d{20}')})
ibanRules.set('TL', {length: 23, exp: new RegExp('TL\\d{21}')})
ibanRules.set('TN', {length: 24, exp: new RegExp('TN\\d{22}')})
ibanRules.set('TR', {length: 26, exp: new RegExp('TR\\d{8}[A-Z0-9]{16}')})
ibanRules.set('UA', {length: 29, exp: new RegExp('UA\\d{8}[A-Z0-9]{19}')})
ibanRules.set('VA', {length: 22, exp: new RegExp('VA\\d{20}')})
ibanRules.set('VG', {length: 24, exp: new RegExp('VG\\d{2}[A-Z]{4}\\d{16}')})
ibanRules.set('XK', {length: 20, exp: new RegExp('XK\\d{18}')})


function isValidIban(candidate: string): boolean {
    if (candidate && candidate.length > 2) {
        const countryCode = candidate.substring(0, 2)
        if (ibanRules.has(countryCode)) {
            const rule = ibanRules.get(countryCode)!!
            const lengthOK = candidate.length === rule.length
            return lengthOK && rule.exp.test(candidate)
        }
    }
    return false
}

const bicRegExp = new RegExp('^[a-z]{6}[0-9a-z]{2}([0-9a-z]{3})$', 'i')

function isValidBic(candidate: string): boolean {
    if (candidate && candidate.length > 7 && candidate.length < 12) {
        return bicRegExp.test(candidate)
    }
    return false;
}

const BankDataPanel = observer(function BankDataPanel() {
    const value = registrationStore.bankData

    const bankDisplayData = registrationStore.bankDisplayData
    const ibanHelp = registrationValidationStore.activated && !registrationStore.bankDisplayData?.valid && 'Bitte trage eine gültige IBAN ein'
    const bicHelp = registrationValidationStore.activated && !isValidBic(registrationStore.bankData.bic || '') && 'Bitte trage eine gültige BIC ein'

    const deviatingTaxPayerHelp = registrationValidationStore.getFor("deviatingTaxPayer.name")


    return <>
        <nav className="panel">
            <p className="panel-heading">Bankdaten</p>
            <div className="panel-block">
                <div style={{paddingTop: '0.5em'}}>
                    <div className={`field required`}>
                        <label className="label">IBAN</label>
                        <div className={`control has-icons-right`}>
                            <input
                                id={'iban-input'}
                                disabled={registrationStore.isReadonly}
                                onInput={e => {
                                    const val = e.currentTarget.value.toUpperCase()
                                    e.currentTarget.value = formatIban(val)
                                }}
                                onChange={async e => {
                                    const val = e.target.value
                                    const candidate = val.replaceAll(' ', '')
                                    const iban = isValidIban(candidate) ? candidate : undefined
                                    if (!iban) {
                                        if (value?.iban !== iban) {
                                            await registrationStore.setIban(iban)
                                        }
                                    } else {
                                        await registrationStore.setIban(iban)
                                    }
                                }}
                                onBlur={() => registrationStore.validate()}
                                maxLength={27}
                                defaultValue={formatIban(value?.iban)}
                                className={`input ${(ibanHelp ? 'is-danger' : '')}`}
                                type="text"
                            />
                            {registrationStore.validatingIban
                                ? <span className="icon is-small is-right">
                                    <span className={"button is-loading"} style={{border: "none"}}></span></span>
                                : <></>}
                        </div>
                    </div>

                    <div className="field">
                        <label className="label">BIC</label>
                        <div className="control">
                            <input
                                disabled={registrationStore.isReadonly}
                                value={bankDisplayData?.bic || ''}
                                onChange={e => {
                                    const value = e.target.value
                                    registrationStore.setBic(value)
                                }}
                                onBlur={() => registrationStore.validate()}
                                className={`input ${(bicHelp ? 'is-danger' : '')}`}
                                readOnly={false}
                                type="text"/>
                        </div>
                        <p className={`help ${(ibanHelp || bicHelp ? 'is-danger' : '')}`}>{ibanHelp ? ibanHelp : bicHelp ? bicHelp :''}</p>
                    </div>
                </div>
            </div>
            {bankDisplayData?.name ? <div className="panel-block">
                <div>
                    <div className="columns">
                        <div className="column is-half">
                            <label className={"label is-small"}>Bank</label>
                            <span className={"is-size-7"}>{bankDisplayData?.name}</span>
                        </div>
                        <div className="column is-half">
                            <label className={"label is-small"}>BIC</label>
                            <span className={"is-size-7"}>{bankDisplayData?.bic}</span>
                        </div>
                    </div>
                </div>
            </div> : <></>}
            <div className={"panel-block"}>
                <div>
                    <label className={"checkbox"}>
                        <input disabled={registrationStore.isReadonly} type={"checkbox"}
                               checked={Boolean(registrationStore.data?.deviatingTaxPayer)}
                               onChange={e => {
                                   registrationStore.setInitialDeviatingTaxPayer(e.target.checked)
                               }}/>
                        Abweichender Zahler Kfz-Steuer
                    </label>

                    {registrationStore.data?.deviatingTaxPayer ? <div style={{paddingTop: "1em"}}>
                        <FieldInput
                            label={"Name"}
                            placeholder={"Kontoinhaber"}
                            value={registrationStore.deviatingTaxPayerName}
                            onChange={v => registrationStore.deviatingTaxPayerName = v}
                            required={true}
                            onBlur={() => registrationStore.validate()}
                            help={deviatingTaxPayerHelp}
                            disabled={registrationStore.isReadonly}
                            invalid={Boolean(deviatingTaxPayerHelp)}
                        />

                        <div className={"columns"}>
                            <div className={"column is-half"}>
                                <FieldInput
                                    label={"Straße"}
                                    value={registrationStore.deviatingTaxPayerStreet}
                                    onChange={v => {
                                        registrationStore.deviatingTaxPayerStreet = v
                                    }}
                                    onBlur={() => registrationStore.validate()}
                                    disabled={registrationStore.isReadonly}
                                />
                            </div>
                            <div className={"column is-one-quarter"}>
                                <FieldInput
                                    label={"Hausnummer"}
                                    value={registrationStore.deviatingTaxPayerHouseNumber}
                                    onChange={v => {
                                        registrationStore.deviatingTaxPayerHouseNumber = v
                                    }}
                                    onBlur={() => registrationStore.validate()}
                                    disabled={registrationStore.isReadonly}
                                />
                            </div>
                            <div className={"column is-one-quarter"}>
                                <FieldInput
                                    label={"Hausnummer Zusatz"}
                                    value={registrationStore.deviatingTaxPayerAddressAddition}
                                    onChange={v => {
                                        registrationStore.deviatingTaxPayerAddressAddition = v
                                    }}
                                    onBlur={() => registrationStore.validate()}
                                    disabled={registrationStore.isReadonly}
                                />
                            </div>
                        </div>

                        <CitySelect
                            onChange={e => {
                                registrationStore.deviatingTaxPayerPostCode = e?.zip || ''
                                registrationStore.deviatingTaxPayerCity = e?.city || ''
                            }}
                            onBlur={() => registrationStore.validate()}
                            city={registrationStore.deviatingTaxPayerCity}
                            zip={registrationStore.deviatingTaxPayerPostCode}
                        />
                    </div> : <></>}
                </div>
            </div>
        </nav>
    </>
})

export {
    BankDataPanel
}
