import {makeAutoObservable} from "mobx";
import {
    AddDocumentParamsEnum,
    api,
    apiStatus,
    catchApi,
    DeliveryInformationDto,
    DeliveryInformationDtoDeliveryDocumentTypeEnum,
    DeliveryInformationDtoDeliveryServiceTypeEnum,
    GenerateDocumentParamsEnum,
    GetDocumentParamsEnum,
    HolderDto,
    HolderDtoGenderEnum,
    OrganizationDto,
    PhoneNumberDto,
    RegistrationDto,
    RegistrationDtoDataDocumentStatusEnum,
    RegistrationDtoOrderStatusEnum,
    RegistrationDtoPoaDocumentStatusEnum,
    RegistrationDtoSepaDocumentStatusEnum,
    RegistrationDtoServiceTypeEnum,
    RegistrationDtoUsageTypeEnum,
    RegistrationDtoVehicleTypeEnum,
    RegistrationStatusDto,
    RemoveDocumentParamsEnum,
    SendDocumentToHolderParamsEnum,
    SubmitRegistrationDto
} from "../api";
import {LicensePlateData, SelectOption} from "../components/ui";
import {equals} from "../utils";
import {
    BankData,
    BankDisplayData,
    DeliveryFormData,
    determineRetainLicensePlateByData,
    getFor,
    mapOrganizationDtoToSelectOption,
    normalizeRegistration,
    toDeliveryAddressDto,
    toDeliveryFormData,
    validateIban
} from "./RegistrationUtils";
import {registrationValidationStore} from "./RegistrationValidationStore";
import {registrationOfficePortalAvailabilityStore} from "./RegistrationOfficePortalAvailabilityStore";
import {registrationNotificationStore} from "./RegistrationNotificationStore";
import {growlStore} from "./GrowlStore";
import {registrationDocumentStore} from "./RegistrationDocumentStore";

export * from "./RegistrationUtils"

class RegistrationStore {
    get organizationInvalid(): boolean {
        return this._organizationInvalid;
    }

    set organizationInvalid(value: boolean) {
        this._organizationInvalid = value;
    }
    get organizationInformation(): string | undefined {
        return this._organizationInformation;
    }

    set organizationInformation(value: string | undefined) {
        this._organizationInformation = value;
    }
    get setup(): boolean {
        return this._setup;
    }

    set setup(value: boolean) {
        this._setup = value;
    }

    get archiving(): boolean {
        return this._archiving;
    }

    set archiving(value: boolean) {
        this._archiving = value;
    }

    get id(): string | undefined {
        return this._id;
    }

    set id(value: string | undefined) {
        this._id = value;
    }

    get allDelivery(): DeliveryInformationDto | undefined {
        return this._allDelivery;
    }

    get allDocumentsPickedUpByRegistrationService(): boolean {
        return Boolean(this._allDelivery) &&
            this._allDelivery?.deliveryServiceType === DeliveryInformationDtoDeliveryServiceTypeEnum.PICKUP_BY_KFZ_REGISTRATION_SERVICE
    }

    set allDelivery(value: DeliveryInformationDto | undefined) {
        if (this.isReadonly) {
            return
        }
        this._allDelivery = value;
    }

    get zbIIDelivery(): DeliveryInformationDto | undefined {
        return this._zbIIDelivery;
    }

    set zbIIDelivery(value: DeliveryInformationDto | undefined) {
        if (this.isReadonly) {
            return
        }
        this._zbIIDelivery = value;
    }

    get zbIDelivery(): DeliveryInformationDto | undefined {
        return this._zbIDelivery;
    }

    set zbIDelivery(value: DeliveryInformationDto | undefined) {
        if (this.isReadonly) {
            return
        }
        this._zbIDelivery = value;
    }

    get bankDisplayData(): BankDisplayData {
        return this._bankDisplayData;
    }

    set bankDisplayData(value: BankDisplayData) {
        this._bankDisplayData = value;
    }

    get validatingIban(): boolean {
        return this._validatingIban;
    }

    set validatingIban(value: boolean) {
        this._validatingIban = value;
    }

    get allOrganizations(): OrganizationDto[] {
        return this._allOrganizations;
    }

    set allOrganizations(value: OrganizationDto[]) {
        this._allOrganizations = value;
    }

    get saving(): boolean {
        return this._saving;
    }

    set saving(value: boolean) {
        this._saving = value;
    }

    get organization(): SelectOption<OrganizationDto> | undefined {
        if (this.data?.organizationId && this.allOrganizations) {
            const el = this.allOrganizations.find(e => e.id === this.data?.organizationId)
            return el ? mapOrganizationDtoToSelectOption(el) : undefined
        }
        return undefined;
    }

    set organization(value: SelectOption<OrganizationDto> | undefined) {
        if (this.isReadonly) {
            return
        }
        if (this.data) {
            this.data.organizationId = value && value.value ? value.value.id : undefined
            this.organizationInformation = undefined
            this.organizationInvalid = false
        }
    }

    get resubmissionScheduledAt(): string | undefined {
        return this.data?.resubmissionScheduledAt
    }

    get data(): RegistrationDto | undefined {
        return this._data;
    }

    set data(value: RegistrationDto | undefined) {
        this._data = value;
    }

    get original(): RegistrationDto | undefined {
        return this._original;
    }

    set original(value: RegistrationDto | undefined) {
        this._original = value;
    }

    get serviceType(): RegistrationDtoServiceTypeEnum | undefined {
        return this.data?.serviceType
    }

    set serviceType(value: RegistrationDtoServiceTypeEnum | undefined) {
        if (this.data && value) {
            if (this.isReadonly) {
                return
            }
            //setting default delivery if from or to AB
            if (value === RegistrationDtoServiceTypeEnum.AB) {
                this.data.deliveryInformation = []
            } else if (this.data.serviceType === RegistrationDtoServiceTypeEnum.AB) {
                this.allDelivery = {
                    deliveryDocumentType: DeliveryInformationDtoDeliveryDocumentTypeEnum.ALL,
                    deliveryServiceType: undefined
                }
                this.data.deliveryInformation = [this.allDelivery]
            }

            this.data.serviceType = value
            // on WZs the vehicle is always deregistered
            if (value === RegistrationDtoServiceTypeEnum.WZ) {
                this.isDeregistered = true
            }
        }
    }

    get dirty(): boolean {
        const o1 = JSON.parse(JSON.stringify(this.original || {}))
        const o2 = JSON.parse(JSON.stringify(this.data || {}))
        return !equals(o1, o2)
    }

    get bankData(): BankData {
        return {
            bic: this.data?.bic,
            iban: this.data?.iban
        }
    }

    set bankData(value: BankData) {
        if (this.data) {
            if (this.isReadonly) {
                return
            }
            if (value.bic) {
                this.data.bic = value.bic
            }
            if (value.iban) {
                this.data.iban = value.iban
            }
        }
    }

    get deviatingTaxPayerAddressAddition(): string {
        return this.data?.deviatingTaxPayer?.addressAddition || ''
    }

    get deviatingTaxPayerCity(): string {
        return this.data?.deviatingTaxPayer?.city || ''
    }

    get deviatingTaxPayerHouseNumber(): string {
        return this.data?.deviatingTaxPayer?.houseNumber || ''
    }

    get deviatingTaxPayerName(): string {
        return this.data?.deviatingTaxPayer?.name || ''
    }

    get deviatingTaxPayerPostCode(): string {
        return this.data?.deviatingTaxPayer?.postCode || ''
    }

    get deviatingTaxPayerStreet(): string {
        return this.data?.deviatingTaxPayer?.street || ''
    }

    set deviatingTaxPayerAddressAddition(value: string) {
        if (this.isReadonly) {
            return
        }
        if (this.data?.deviatingTaxPayer) {
            this.data.deviatingTaxPayer.addressAddition = value || undefined
        }
    }

    set deviatingTaxPayerCity(value: string) {
        if (this.isReadonly) {
            return
        }
        if (this.data?.deviatingTaxPayer) {
            this.data.deviatingTaxPayer.city = value || undefined
        }
    }

    set deviatingTaxPayerHouseNumber(value: string) {
        if (this.isReadonly) {
            return
        }
        if (this.data?.deviatingTaxPayer) {
            this.data.deviatingTaxPayer.houseNumber = value || undefined
        }
    }

    set deviatingTaxPayerName(value: string) {
        if (this.isReadonly) {
            return
        }
        if (this.data?.deviatingTaxPayer) {
            this.data.deviatingTaxPayer.name = value || undefined
        }
    }

    set deviatingTaxPayerPostCode(value: string) {
        if (this.isReadonly) {
            return
        }
        if (this.data?.deviatingTaxPayer) {
            this.data.deviatingTaxPayer.postCode = value || undefined
        }
    }

    set deviatingTaxPayerStreet(value: string) {
        if (this.isReadonly) {
            return
        }
        if (this.data?.deviatingTaxPayer) {
            this.data.deviatingTaxPayer.street = value || undefined
        }
    }

    get primaryDeliveryType(): DeliveryInformationDtoDeliveryDocumentTypeEnum {
        if (this.allDelivery) {
            return DeliveryInformationDtoDeliveryDocumentTypeEnum.ALL
        }
        return (this.serviceType === RegistrationDtoServiceTypeEnum.TZ)
            ? DeliveryInformationDtoDeliveryDocumentTypeEnum.ZB_I_AND_BADGES
            : DeliveryInformationDtoDeliveryDocumentTypeEnum.ZB_II
    }

    set primaryDeliveryType(val: DeliveryInformationDtoDeliveryDocumentTypeEnum) {
        if (this.isReadonly) {
            return
        }
        if (val === DeliveryInformationDtoDeliveryDocumentTypeEnum.ALL) {
            this.allDelivery = {
                deliveryDocumentType: DeliveryInformationDtoDeliveryDocumentTypeEnum.ALL,
                deliveryServiceType: DeliveryInformationDtoDeliveryServiceTypeEnum.PICKUP_BY_KFZ_REGISTRATION_SERVICE
            }
        } else {
            this.setZBIAndIIDeliveryFromData()
        }
        this.projectDeliveryData()
    }

    get allOption(): DeliveryInformationDtoDeliveryServiceTypeEnum | undefined {
        return this.allDelivery?.deliveryServiceType
    }

    set allOption(val: DeliveryInformationDtoDeliveryServiceTypeEnum | undefined) {
        if (this.isReadonly) {
            return
        }
        if (!this.allDelivery) {
            this.allDelivery = {
                deliveryDocumentType: DeliveryInformationDtoDeliveryDocumentTypeEnum.ALL
            }
        }
        this.allDelivery.deliveryServiceType = val;
        this.projectDeliveryData()
    }

    get zbIOption(): DeliveryInformationDtoDeliveryServiceTypeEnum {
        return (this.zbIDelivery?.deliveryServiceType || (
            this.primaryDeliveryType !== DeliveryInformationDtoDeliveryDocumentTypeEnum.ALL
                ? DeliveryInformationDtoDeliveryServiceTypeEnum.SENDING_TO_VEHICLE_OWNER
                : DeliveryInformationDtoDeliveryServiceTypeEnum.PICKUP_BY_KFZ_REGISTRATION_SERVICE
        ))
    }

    set zbIOption(val: DeliveryInformationDtoDeliveryServiceTypeEnum) {
        if (this.isReadonly) {
            return
        }
        if (!this.zbIDelivery) {
            this.zbIDelivery = {
                deliveryDocumentType: DeliveryInformationDtoDeliveryDocumentTypeEnum.ZB_I_AND_BADGES
            }
        }
        this.zbIDelivery.deliveryServiceType = val
        if (
            val === DeliveryInformationDtoDeliveryServiceTypeEnum.SENDING_TO_VEHICLE_OWNER &&
            this.zbIIDelivery?.deliveryServiceType === val
        ) {
            this.zbIIDelivery.deliveryServiceType = DeliveryInformationDtoDeliveryServiceTypeEnum.SENDING_TO_THIRD
        }
        this.projectDeliveryData()
    }

    get zbIIOption(): DeliveryInformationDtoDeliveryServiceTypeEnum {
        return (this.zbIIDelivery?.deliveryServiceType || DeliveryInformationDtoDeliveryServiceTypeEnum.SENDING_TO_THIRD)
    }

    set zbIIOption(val: DeliveryInformationDtoDeliveryServiceTypeEnum) {
        if (this.isReadonly) {
            return
        }
        if (!this.zbIIDelivery) {
            this.zbIIDelivery = {
                deliveryDocumentType: DeliveryInformationDtoDeliveryDocumentTypeEnum.ZB_II
            }
        }
        this.zbIIDelivery.deliveryServiceType = val;
        this.projectDeliveryData()
    }

    get formValueForAll(): DeliveryFormData {
        return toDeliveryFormData(this.allDelivery?.deliveryAddress || {})
    }

    get formValueForZBI(): DeliveryFormData {
        return toDeliveryFormData(this.zbIDelivery?.deliveryAddress || {})
    }

    get formValueForZBII(): DeliveryFormData {
        return toDeliveryFormData(this.zbIIDelivery?.deliveryAddress || {})
    }


    set formValueForAll(val: DeliveryFormData) {
        if (this.isReadonly) {
            return
        }
        if (this.allDelivery) {
            this.allDelivery.deliveryServiceType = DeliveryInformationDtoDeliveryServiceTypeEnum.PICKUP_BY_THIRD
            this.allDelivery.deliveryAddress = toDeliveryAddressDto(val)
        }
    }

    set formValueForAllSendingToThird(val: DeliveryFormData) {
        if (this.isReadonly) {
            return
        }
        if (this.allDelivery) {
            this.allDelivery.deliveryServiceType = DeliveryInformationDtoDeliveryServiceTypeEnum.SENDING_TO_THIRD
            this.allDelivery.deliveryAddress = toDeliveryAddressDto(val)
        }
    }

    set formValueForZBI(val: DeliveryFormData) {
        if (this.isReadonly) {
            return
        }
        if (!this.zbIDelivery) {
            this.zbIOption = DeliveryInformationDtoDeliveryServiceTypeEnum.SENDING_TO_THIRD
        }
        if (this.zbIDelivery) {
            this.zbIDelivery.deliveryServiceType = DeliveryInformationDtoDeliveryServiceTypeEnum.SENDING_TO_THIRD
            this.zbIDelivery.deliveryAddress = toDeliveryAddressDto(val)
        }
    }

    set formValueForZBII(val: DeliveryFormData) {
        if (this.isReadonly) {
            return
        }

        if (!this.zbIIDelivery) {
            this.zbIIOption = DeliveryInformationDtoDeliveryServiceTypeEnum.SENDING_TO_THIRD
        }
        if (this.zbIIDelivery) {
            this.zbIIDelivery.deliveryServiceType = DeliveryInformationDtoDeliveryServiceTypeEnum.SENDING_TO_THIRD
            this.zbIIDelivery.deliveryAddress = toDeliveryAddressDto(val)
        }
    }

    get dataConsentStatus(): RegistrationDtoDataDocumentStatusEnum {
        return this.data?.dataDocumentStatus || RegistrationDtoDataDocumentStatusEnum.NOT_SET
    }

    get poaDocumentStatus(): RegistrationDtoPoaDocumentStatusEnum {
        return this.data?.poaDocumentStatus || RegistrationDtoPoaDocumentStatusEnum.NOT_SET
    }

    get sepaDocumentStatus(): RegistrationDtoSepaDocumentStatusEnum {
        return this.data?.sepaDocumentStatus || RegistrationDtoSepaDocumentStatusEnum.NOT_SET
    }

    get status(): RegistrationDtoOrderStatusEnum {
        return this.data?.orderStatus || RegistrationDtoOrderStatusEnum.CREATED
    }

    get hasEmail(): boolean {
        return Boolean(this.data?.holder?.emailAddress) || false
    }

    get isLegalPerson(): boolean {
        return this.data?.holder?.corporate || false
    }

    get hasAllRegisterFields(): boolean {
        return Boolean(this.data?.holder?.registerType) && Boolean(this.data?.holder?.registerNumber) && Boolean(this.data?.holder?.courtId)
    }

    get evbNumber(): string {
        return this.data?.companyInsuranceNumber || ''
    }

    set evbNumber(val: string) {
        if (this.data) {
            if (this.isReadonly) {
                return
            }
            this.data.companyInsuranceNumber = val
        }
    }

    get isCorporate(): boolean {
        return this.data?.holder?.corporate || false
    }

    set isCorporate(val: boolean) {
        if (this.isReadonly) {
            return
        }
        if (this.data) {
            if (!this.data?.holder) {
                this.data.holder = {
                    corporate: val
                }
            } else {
                this.data.holder.corporate = val
            }
        }
    }

    get holder(): HolderDto {
        // {...def, ...JSON.parse(JSON.stringify(obj))}
        const def: HolderDto = {
            corporate: false,
            emailAddress: '',
            name: '',
            addressAddition: '',
            city: '',
            birthName: '',
            birthNameSuffix: '',
            cityOfBirth: '',
            courtId: '',
            dateOfBirth: '',
            doctorate: '',
            economicSector: undefined,
            firstName: '',
            gender: undefined,
            houseNumber: '',
            nameSuffix: '',
            postCode: '',
            phoneNumber: {
                number: '',
                countryCode: ''
            }, //TODO
            street: '',
            registerNumber: '',
            registerType: undefined
        }
        const holder = JSON.parse(JSON.stringify(this.data?.holder || {}))
        const phoneNumber: PhoneNumberDto = holder.phoneNumber || {}
        const {number, countryCode} = phoneNumber
        return {
            ...def, ...holder, phoneNumber: {
                number: number || '',
                countryCode: countryCode || ''
            }
        }
    }

    set holder(val: HolderDto) {
        if (this.isReadonly) {
            return
        }
        const {
            corporate,
            emailAddress,
            name,
            addressAddition,
            city,
            birthName,
            birthNameSuffix,
            cityOfBirth,
            courtId,
            dateOfBirth,
            doctorate,
            economicSector,
            firstName,
            gender,
            houseNumber,
            nameSuffix,
            postCode,
            phoneNumber,
            street,
            registerNumber,
            registerType
        } = val

        if (this.data) {
            const holder: HolderDto = {
                corporate: corporate
            }
            const {
                number,
                countryCode
            } = phoneNumber || {}

            holder.emailAddress = emailAddress || undefined
            holder.name = name || undefined
            holder.addressAddition = addressAddition || undefined
            holder.city = city || undefined
            holder.birthName = corporate ? undefined : birthName || undefined
            holder.birthNameSuffix = corporate ? undefined : birthNameSuffix || undefined
            holder.cityOfBirth = corporate ? undefined : cityOfBirth || undefined
            holder.courtId = courtId ? (courtId || undefined) : undefined
            holder.dateOfBirth = corporate ? undefined : dateOfBirth || undefined
            holder.doctorate = corporate ? undefined : doctorate || undefined
            holder.economicSector = corporate ? (economicSector || undefined) : undefined
            holder.firstName = corporate ? undefined : firstName || undefined
            holder.gender = corporate ? HolderDtoGenderEnum.NOT_PROVIDED : gender || undefined
            holder.houseNumber = houseNumber || undefined
            holder.nameSuffix = corporate ? undefined : nameSuffix || undefined
            holder.postCode = postCode || undefined
            holder.street = street || undefined
            holder.registerNumber = corporate ? (registerNumber || undefined) : undefined
            holder.registerType = corporate ? (registerType || undefined) : undefined

            holder.phoneNumber = number && countryCode ? {
                number: number,
                countryCode: countryCode
            } : undefined

            this.data.holder = holder

        }
    }

    get vin(): string {
        return this.data?.vehicleIdentificationNumber || ''
    }

    set vin(value: string) {
        if (this.isReadonly) {
            return
        }
        if (this.data) {
            this.data.vehicleIdentificationNumber = value || undefined
        }
    }

    get vehicleType(): RegistrationDtoVehicleTypeEnum {
        return this.data?.vehicleType || RegistrationDtoVehicleTypeEnum.CAR
    }

    set vehicleType(val: RegistrationDtoVehicleTypeEnum) {
        if (this.isReadonly) {
            return
        }
        if (this.data) {
            this.data.vehicleType = val
        }
    }

    get usageType(): RegistrationDtoUsageTypeEnum | undefined {
        return this.data?.usageType
    }

    set usageType(val: RegistrationDtoUsageTypeEnum | undefined) {
        if (this.isReadonly) {
            return
        }
        if (this.data) {
            this.data.usageType = val
        }
    }

    get certificateOfRegistrationNumber(): string {
        return this.data?.certificateOfRegistrationNumber || ''
    }

    set certificateOfRegistrationNumber(val: string) {
        if (this.isReadonly) {
            return
        }
        if (this.data) {
            this.data.certificateOfRegistrationNumber = val || undefined
        }
    }

    get certificateOfRegistrationSerial(): string {
        return this.data?.certificateOfRegistrationSerial || ''
    }

    set certificateOfRegistrationSerial(val: string) {
        if (this.isReadonly) {
            return
        }
        if (this.data) {
            this.data.certificateOfRegistrationSerial = val || undefined
        }
    }

    get licensePlate(): LicensePlateData {
        const seasonStart = this.data?.licensePlate?.seasonStart
        const seasonEnd = this.data?.licensePlate?.seasonEnd
        const hasSeasonStart = seasonStart || seasonStart === 0
        const hasSeasonEnd = seasonEnd || seasonEnd === 0
        const hasSeason = Boolean(hasSeasonStart || hasSeasonEnd)
        return {
            season: hasSeason ? {
                start: seasonStart || '',
                end: seasonEnd || ''
            } : undefined,
            identificationNumber: this.data?.licensePlate?.identificationNumber || '',
            identification: this.data?.licensePlate?.identification || '',
            distinguishingSign: this.data?.licensePlate?.distinguishingSign || '',
            addition: this.data?.licensePlate?.electric ? 'E' : undefined
        }
    }

    get finishedLicensePlate(): LicensePlateData | undefined {
        const finishedLicensePlate = this.data?.finishedLicensePlate
        const finalState = this.status === RegistrationDtoOrderStatusEnum.FINISHED || this.status === RegistrationDtoOrderStatusEnum.SUCCESS
        //
        if (finishedLicensePlate || finalState) {
            const hasSeason = Boolean(finishedLicensePlate?.seasonStart || finishedLicensePlate?.seasonEnd)
            return {
                season: hasSeason ? {
                    start: finishedLicensePlate?.seasonStart || '',
                    end: finishedLicensePlate?.seasonEnd || ''
                } : undefined,
                identificationNumber: finishedLicensePlate?.identificationNumber || '',
                identification: finishedLicensePlate?.identification || '',
                distinguishingSign: finishedLicensePlate?.distinguishingSign || '',
                addition: finishedLicensePlate?.electric ? 'E' : undefined
            }
        }
        return undefined
    }

    get finishedCertificateOfRegistrationNumber(): string | undefined {
        return this.data?.finishedCertificateOfRegistrationNumber?.number || undefined
    }

    get finishedCertificateOfRegistrationSerial(): string | undefined {
        return this.data?.finishedCertificateOfRegistrationNumber?.serial || undefined
    }

    set licensePlate(val: LicensePlateData) {
        if (this.isReadonly) {
            return
        }
        if (this.data) {
            this.data.licensePlate = {
                distinguishingSign: val.distinguishingSign || undefined,
                electric: val.addition === 'E',
                identification: val.identification || undefined,
                identificationNumber: val.identificationNumber || undefined,
                seasonEnd: val.season?.end || undefined,
                seasonStart: val.season?.start || undefined
            }
        }
    }

    get previousLicensePlate(): LicensePlateData {
        const hasSeason = Boolean(
            this.data?.previousLicensePlate?.seasonStart
            || this.data?.previousLicensePlate?.seasonEnd)
        return {
            season: hasSeason ? {
                start: this.data?.previousLicensePlate?.seasonStart || 0,
                end: this.data?.previousLicensePlate?.seasonEnd || 0
            } : undefined,
            identificationNumber: this.data?.previousLicensePlate?.identificationNumber || '',
            identification: this.data?.previousLicensePlate?.identification || '',
            distinguishingSign: this.data?.previousLicensePlate?.distinguishingSign || '',
            addition: this.data?.previousLicensePlate?.electric ? 'E' : undefined
        }
    }

    set previousLicensePlate(val: LicensePlateData) {
        if (this.isReadonly) {
            return
        }
        if (this.data) {
            this.data.previousLicensePlate = {
                distinguishingSign: val.distinguishingSign || undefined,
                electric: val.addition === 'E',
                identification: val.identification || undefined,
                identificationNumber: val.identificationNumber || undefined,
                seasonEnd: val.season?.end || undefined,
                seasonStart: val.season?.start || undefined
            }
        }
    }

    updatePreviousLicensePlate(val: LicensePlateData){
        this.previousLicensePlate = val
        if (this.isRetainLicensePlate) {
            this.licensePlate = this.previousLicensePlate
        }
    }

    get reservationNumber(): string {
        return this.data?.reservationNumber || ''
    }

    set reservationNumber(val: string) {
        if (this.isReadonly) {
            return
        }
        if (this.data) {
            this.data.reservationNumber = val || undefined
        }
    }

    get anyDocumentReadyForSignature(): boolean {
        const dataReadyToSign = this.dataConsentStatus === RegistrationDtoDataDocumentStatusEnum.UNSIGNED || this.dataConsentStatus === RegistrationDtoDataDocumentStatusEnum.SUBMISSION_FAILED;
        const poaReadyToSign = this.poaDocumentStatus === RegistrationDtoPoaDocumentStatusEnum.UNSIGNED || this.poaDocumentStatus === RegistrationDtoPoaDocumentStatusEnum.SUBMISSION_FAILED;
        const sepaReadyToSign = this.sepaDocumentStatus === RegistrationDtoSepaDocumentStatusEnum.UNSIGNED || this.sepaDocumentStatus === RegistrationDtoSepaDocumentStatusEnum.SUBMISSION_FAILED;

        if (this.serviceType === RegistrationDtoServiceTypeEnum.HA) {
            if (this.isCorporate) {
                return poaReadyToSign;
            } else {
                return dataReadyToSign || poaReadyToSign
            }
        }

        if (this.isCorporate) {
            return poaReadyToSign || sepaReadyToSign;
        }

        return dataReadyToSign || poaReadyToSign || sepaReadyToSign;
    }

    get allDocumentsUploaded(): boolean {
        const dataDocumentSet = this.dataConsentStatus !== RegistrationDtoDataDocumentStatusEnum.NOT_SET;
        const poaDocumentSet = this.poaDocumentStatus !== RegistrationDtoPoaDocumentStatusEnum.NOT_SET;
        const sepaDocumentSet = this.sepaDocumentStatus !== RegistrationDtoSepaDocumentStatusEnum.NOT_SET;

        if (this.serviceType === RegistrationDtoServiceTypeEnum.HA) {
            if (this.isCorporate) {
                return poaDocumentSet;
            } else {
                return dataDocumentSet && poaDocumentSet
            }
        }

        if (this.isCorporate) {
            return poaDocumentSet && sepaDocumentSet;
        }

        return dataDocumentSet && poaDocumentSet && sepaDocumentSet;
    }

    get signatureProcessStarted(): boolean {
        const dataDocumentSubmitted = this.dataConsentStatus === RegistrationDtoDataDocumentStatusEnum.SUBMITTED;
        const sepaDocumentSubmitted = this.sepaDocumentStatus === RegistrationDtoSepaDocumentStatusEnum.SUBMITTED;
        const poaDocumentSubmitted = this.poaDocumentStatus === RegistrationDtoPoaDocumentStatusEnum.SUBMITTED;

        if (this.serviceType === RegistrationDtoServiceTypeEnum.HA) {
            if (this.isCorporate) {
                return poaDocumentSubmitted;
            } else {
                return dataDocumentSubmitted || poaDocumentSubmitted
            }
        }

        if (this.isCorporate) {
            return poaDocumentSubmitted || sepaDocumentSubmitted;
        }

        return dataDocumentSubmitted || poaDocumentSubmitted || sepaDocumentSubmitted;
    }

    get signatureProcessFinished(): boolean {
        const dataDocumentSigned = this.dataConsentStatus === RegistrationDtoDataDocumentStatusEnum.SIGNED;
        const sepaDocumentSigned = this.sepaDocumentStatus === RegistrationDtoSepaDocumentStatusEnum.SIGNED;
        const poaDocumentSigned = this.poaDocumentStatus === RegistrationDtoPoaDocumentStatusEnum.SIGNED;

        if (this.serviceType === RegistrationDtoServiceTypeEnum.HA) {
            if (this.isCorporate) {
                return poaDocumentSigned;
            } else {
                return dataDocumentSigned && poaDocumentSigned
            }
        }

        if (this.isCorporate) {
            return poaDocumentSigned && sepaDocumentSigned;
        }

        return dataDocumentSigned && poaDocumentSigned && sepaDocumentSigned;
    }

    get documentsReadyForSignature(): boolean {
        if (this.isLegalPerson) {
            return this.hasEmail && this.hasAllRegisterFields && (this.allDocumentsUploaded || this.selfRegistration) && this.anyDocumentReadyForSignature
        }
        return this.hasEmail && (this.allDocumentsUploaded || this.selfRegistration) && this.anyDocumentReadyForSignature
    }

    get previousLicensePlateReservation(): boolean {
        return this.data?.previousLicensePlateReservation || false
    }

    set previousLicensePlateReservation(val: boolean) {
        if (this.isReadonly) {
            return
        }
        if (this.data) {
            this.data.previousLicensePlateReservation = val
        }
    }

    get certificateOfDestruction() {
        const cod = this.data?.certificateOfDestruction
        return cod ? {
            companyNumber: cod?.companyNumber || '',
            date: cod?.date || ''
        } : undefined
    }

    set certificateOfDestruction(val: undefined | { companyNumber: string, date: string }) {
        if (this.isReadonly) {
            return
        }
        if (this.data) {
            this.data.certificateOfDestruction = val ? {
                companyNumber: val.companyNumber,
                date: val.date || undefined
            } : val
        }
    }

    get isDeregistered(): boolean {
        // this.serviceType === RegistrationDtoServiceTypeEnum.WZ
        return this.data?.isDeregistered || false
    }

    set isDeregistered(val: boolean) {
        if (this.data) {
            if (this.isReadonly) {
                return
            }
            this.data.isDeregistered = val
        }
    }

    updateDeregistered(val: boolean){
        this.isDeregistered = val
        if (this.isDeregistered && this.isRetainLicensePlate) {
            this.isRetainLicensePlate = false
        }
    }

    get isRetainLicensePlate(): boolean {
        return this._isRetainLicensePlate
    }

    set isRetainLicensePlate(val: boolean) {
        if (this.isReadonly) {
            return
        }
        this._isRetainLicensePlate = val
    }

    updateRetainLicensePlate(val: boolean){
        this.isRetainLicensePlate = val
        this.licensePlate = this.isRetainLicensePlate ? this.previousLicensePlate : {
            distinguishingSign: '',
            identification: '',
            identificationNumber: '',
        }
        if (this.isRetainLicensePlate && this.isDeregistered) {
            this.isDeregistered = false
        }
    }

    get isReadonly(): boolean {
        if (this.data && this.setup) {
            const status = registrationStore.status
            return this.data.archived ||
                status === RegistrationDtoOrderStatusEnum.SUCCESS ||
                status === RegistrationDtoOrderStatusEnum.FINISHED ||
                status === RegistrationDtoOrderStatusEnum.SUBMITTED ||
                status === RegistrationDtoOrderStatusEnum.RESUBMISSION_SCHEDULED
        }
        return false
    }

    get licensePlateDisplay(): string {
        if (this.data) {
            switch (this.serviceType!!) {
                case RegistrationDtoServiceTypeEnum.NZ:
                case RegistrationDtoServiceTypeEnum.TZ:
                case RegistrationDtoServiceTypeEnum.WZ:
                case RegistrationDtoServiceTypeEnum.UI:
                    return `${this.licensePlate.distinguishingSign || ''} ${this.licensePlate.identification || ''}${this.licensePlate.identificationNumber || ''}`
                case RegistrationDtoServiceTypeEnum.AB:
                    return `${this.previousLicensePlate.distinguishingSign || ''} ${this.previousLicensePlate.identification || ''}${this.previousLicensePlate.identificationNumber || ''}`
            }
        }
        return ''
    }

    get submitDto(): SubmitRegistrationDto {
        return {
            securityCode: this.data?.securityCode || '',
            verificationCode: this.data?.verificationCode || '',
            frontSealCode: this.data?.previousLicensePlateSealFront || '',
            rearSealCode: this.data?.previousLicensePlateSealRear || ''
        }
    }

    set submitDto(val: SubmitRegistrationDto) {
        if (this.data && val) {
            this.data.securityCode = val.securityCode || undefined
            this.data.verificationCode = val.verificationCode || undefined
            this.data.previousLicensePlateSealFront = val.frontSealCode || undefined
            this.data.previousLicensePlateSealRear = val.rearSealCode || undefined
        }
    }

    get archived(): boolean {
        return this.data?.archived === true
    }

    get loading(): boolean {
        return this._loading
    }

    get selfRegistration(): boolean {
        return this.data?.selfRegistration || false
    }

    get hasFrontLicensePlate(): boolean {
        const vehicleType = this.vehicleType

        return vehicleType === RegistrationDtoVehicleTypeEnum.CAR
    }

    get readyToGenerateSEPA(): boolean {
        const data = this.data
        if (!data || this.dirty) {
            return false
        }

        let sepaFieldsPresent = true
        sepaFieldsPresent &&=
            Boolean(data.iban) && Boolean(data.bic) && this.allRelevantHolderDataSet
        if (!data.holder?.corporate) {
            sepaFieldsPresent &&= Boolean(data.holder?.firstName)
        }
        return sepaFieldsPresent
    }

    get readyToGeneratePOA(): boolean {
        const data = this.data
        if (!data || this.dirty) {
            return false
        }
        let poaFieldsPresent = true
        poaFieldsPresent &&= this.allRelevantHolderDataSet
        if (!data.holder?.corporate) {
            poaFieldsPresent &&= Boolean(data.holder?.firstName)
            poaFieldsPresent &&= Boolean(data.holder?.dateOfBirth)
            poaFieldsPresent &&= Boolean(data.holder?.cityOfBirth)
        } else {
            poaFieldsPresent &&= Boolean(data.holder?.economicSector)
        }
        poaFieldsPresent &&=
            Boolean(data.vehicleIdentificationNumber) &&
            data.vehicleIdentificationNumber?.length === 17
        poaFieldsPresent &&= !data.selfRegistration
        poaFieldsPresent &&= Boolean(data.deliveryInformation)
            && Boolean(data.deliveryInformation?.length)
            && Boolean(data.deliveryInformation?.[0].deliveryServiceType)

        return poaFieldsPresent
    }

    get readyToGenerateDPA(): boolean {
        const data = this.data
        if (!data || this.dirty) {
            return false
        }
        let dataConsentFieldsPresent = true
        dataConsentFieldsPresent &&= this.allRelevantHolderDataSet
        if (!data.holder?.corporate) {
            dataConsentFieldsPresent &&= Boolean(data.holder?.firstName)
        } else {
            dataConsentFieldsPresent = false
        }
        return dataConsentFieldsPresent
    }

    private get allRelevantHolderDataSet(): boolean {
        return (
            Boolean(this.data?.holder?.street) &&
            Boolean(this.data?.holder?.houseNumber) &&
            Boolean(this.data?.holder?.postCode) &&
            Boolean(this.data?.holder?.name) &&
            Boolean(this.data?.holder?.emailAddress) &&
            Boolean(this.data?.vehicleIdentificationNumber)
        );
    }

    constructor() {
        makeAutoObservable(this)
    }

    private _id: string | undefined
    private _setup: boolean = false
    private _original: RegistrationDto | undefined
    private _data: RegistrationDto | undefined
    private _allOrganizations: OrganizationDto[] = []
    private _loading: boolean = false
    private _saving: boolean = false
    private _archiving: boolean = false
    private _validatingIban: boolean = false
    private _organizationInformation: string | undefined;
    private _organizationInvalid: boolean = false;
    private _bankDisplayData: BankDisplayData = {
        valid: false,
        iban: '',
        bic: '',
        name: ''
    }

    private _zbIDelivery: DeliveryInformationDto | undefined
    private _zbIIDelivery: DeliveryInformationDto | undefined
    private _allDelivery: DeliveryInformationDto | undefined
    private _isRetainLicensePlate = false

    private projectDeliveryData() {
        if (this.data) {
            if (this.serviceType === RegistrationDtoServiceTypeEnum.AB) {
                this.data.deliveryInformation = []
            } else if(this.allDelivery) {
                this.data.deliveryInformation = [this.allDelivery]
            } else {
                const deliveryInformation = []
                if (this.zbIDelivery) {
                    deliveryInformation.push(this.zbIDelivery)
                }
                if (this.zbIIDelivery) {
                    deliveryInformation.push(this.zbIIDelivery)
                }
                this.data.deliveryInformation = deliveryInformation
            }
        }
    }

    async finish() {
        this.saving = true
        await catchApi(async () => {
            await api.v1.finishRegistration(this.data?.id!!);
            await registrationStore.load()
        }).finally(() => {
            this.saving = false
        })
    }

    async save() {
        this.saving = true

        await catchApi(async () => {
            const out = JSON.parse(JSON.stringify(this.data!!)) as RegistrationDto
            // we need to delete delivery address, otherwise the backend will change type to "by third"
            if (this.allDocumentsPickedUpByRegistrationService
                && out.deliveryInformation?.length!! > 0
                && out.deliveryInformation!![0].deliveryAddress) {
                delete out.deliveryInformation!![0].deliveryAddress
            }
            const response = await api.v1.updateRegistration(this.data?.id!!, out)
            this.loaded(response.data)
            await registrationValidationStore.validate()
            registrationValidationStore.activated = true
        }).finally(() => {
            this.saving = false
        })
    }

    async upload(file: File, type: AddDocumentParamsEnum) {
        await catchApi(async () => {
            try {
                await api.v1.addDocument(
                    type,
                    this.data?.id!!,
                    {
                        file: file
                    }
                )
            } catch (e) {
                if (e && e instanceof Response && e.status === 400) {
                    apiStatus.addError({
                        statusText: `Validierungsfehler`,
                        data: `Das hochgeladene Dokument ist kein valides PDF-Dokument und kann nicht verwendet werden.`
                    })
                    return
                }
                throw e
            }
        })
    }

    async load(idParam?: string) {
        if (idParam) {
            if (this.id !== idParam) {
                this.resetBankDataInfo()
            }
            this.id = idParam
            registrationValidationStore.id = idParam
        }
        const id = this.id
        if (!id) {
            return
        }
        this._loading = true
        await catchApi(async () => {
            registrationDocumentStore.clear()
            const organizationResponse = await api.v1.listOrganizations()
            const loadedOrganizations = organizationResponse.data || []

            const response = await api.v1.getRegistration(id)
            this.loaded(response.data)
            this.allOrganizations = loadedOrganizations.filter(e => !e.disabled)
            if (this.data?.organizationId) {
                const selectedOrganization = loadedOrganizations.find(e => e.id === this.data?.organizationId)
                if (selectedOrganization?.disabled) {
                    this.organization = undefined
                    this.organizationInformation = `Standort '${selectedOrganization.name}' wurde deaktiviert.`
                    this.organizationInvalid = true
                }
            }

            await Promise.all(
                [
                    registrationValidationStore.validate(),
                    registrationDocumentStore.load(this.id),
                    registrationNotificationStore.load(this.id)
                ]
            )

            if (this.original?.iban) {
                await this.setIban(this.original.iban)
            }
            const holder = this.data?.holder
            if (holder?.postCode && holder.city) {
                await registrationOfficePortalAvailabilityStore.trigger(holder.postCode, holder.city)
            }
        }).finally(() => {
            this._loading = false
        })
    }

    private loaded(data: RegistrationDto) {
        this.setup = false
        this.original = normalizeRegistration(data)
        this.data = JSON.parse(JSON.stringify(data)) as RegistrationDto // we need a deep clone

        const deliveryData = this.data.deliveryInformation || []

        if (deliveryData.length === 2) {
            this.setZBIAndIIDeliveryFromData()
        } else {
            const allAddress = (deliveryData.length === 1 && deliveryData[0].deliveryAddress) ? deliveryData[0].deliveryAddress : undefined
            const deliveryServiceType = (deliveryData.length === 1 && deliveryData[0].deliveryServiceType) ? deliveryData[0].deliveryServiceType : undefined
            this.allDelivery = {
                deliveryDocumentType: DeliveryInformationDtoDeliveryDocumentTypeEnum.ALL,
                deliveryServiceType: deliveryServiceType,
                deliveryAddress: allAddress
            }
            this.zbIDelivery = undefined
            this.zbIIDelivery = undefined
        }

        // this flag sometimes comes incorrect from the backend - super hacky!
        if (!this.isDeregistered && this.serviceType === RegistrationDtoServiceTypeEnum.WZ) {
            this.isDeregistered = true
        }
        this.projectDeliveryData()
        this.isRetainLicensePlate = determineRetainLicensePlateByData(data)
        this.setup = true
    }

    private setZBIAndIIDeliveryFromData() {
        this.allDelivery = undefined
        this.zbIDelivery = getFor(this.data!!, DeliveryInformationDtoDeliveryDocumentTypeEnum.ZB_I_AND_BADGES) || {
            deliveryDocumentType: DeliveryInformationDtoDeliveryDocumentTypeEnum.ZB_I_AND_BADGES,
            deliveryServiceType: DeliveryInformationDtoDeliveryServiceTypeEnum.SENDING_TO_VEHICLE_OWNER,
            deliveryAddress: {}
        }
        this.zbIIDelivery = getFor(this.data!!, DeliveryInformationDtoDeliveryDocumentTypeEnum.ZB_II) || {
            deliveryDocumentType: DeliveryInformationDtoDeliveryDocumentTypeEnum.ZB_II,
            deliveryServiceType: DeliveryInformationDtoDeliveryServiceTypeEnum.SENDING_TO_THIRD,
            deliveryAddress: {}
        }
    }

    async searchOrganization(value: string): Promise<SelectOption<OrganizationDto>[]> {
        return await catchApi(async () => {
            const response = await api.v1.listOrganizations()
            const data = response.data
            const v = value.toLowerCase()
            return data.filter(o => {
                return o.id === v || o.costCenter === v || o.name.toLowerCase().split(v).length > 1
            }).map(mapOrganizationDtoToSelectOption)
        }) || []

    }

    async setIban(iban: string | undefined) {
        if (iban) {
            this.validatingIban = true
            try {
                const display = await validateIban(iban, this.bankData.bic)
                if (display && display.valid) {
                    this.bankData = {
                        iban: display.iban,
                        bic: display.bic
                    }
                    this.bankDisplayData = display
                    return
                }
            } finally {
                this.validatingIban = false
            }
        }
        this.resetBankDataInfo()
    }

    setBic(value: string) {
        const bankData = registrationStore.bankData
        this.bankData = {
            iban: bankData.iban,
            bic: value
        }
        this.bankDisplayData = {
            ...this.bankDisplayData,
            bic: value
        }
    }

    resetBankDataInfo() {
        this.bankData = {}
        this.bankDisplayData = {
            valid: false,
            iban: '',
            bic: '',
            name: ''
        }
    }

    setInitialDeviatingTaxPayer(checked: boolean) {
        if (!this.data) {
            return;
        }
        if (checked) {
            this.data.deviatingTaxPayer = this.data?.deviatingTaxPayer || this.original?.deviatingTaxPayer || {}
        } else {
            this.data.deviatingTaxPayer = undefined
        }
    }

    async deleteDocument(type: RemoveDocumentParamsEnum) {
        const id = this.data?.id
        if (id) {
            await catchApi(async () => {
                await api.v1.removeDocument(type, id)
            })
        }
    }

    async generateDocument(type: GenerateDocumentParamsEnum) {
        const id = this.data?.id;
        if (id) {
            await catchApi(async () => {
                await api.v1.generateDocument(type, id)
            })

        }
    }

    async downloadDocument(type: GetDocumentParamsEnum) {
        const id = this.data?.id
        if (id) {
            return await catchApi(async () => {
                const result = await api.v1.getDocument(id, type, {
                    format: "blob"
                })
                return await result.blob()
            })

        }
    }

    async sendDocumentToHolder(type: SendDocumentToHolderParamsEnum) {
        const id = this.data?.id
        if (id) {
            await catchApi(async () => {
                await api.v1.sendDocumentToHolder(type, id)
            })
        }
    }

    async sendDocumentTo(type: SendDocumentToHolderParamsEnum, email: string) {
        const id = this.data?.id
        if (id) {
            await catchApi(async () => {
                await api.v1.sendDocumentToHolder(type, id, {email: email})
            })
        }
    }

    async unarchive() {
        this.archiving = true
        await catchApi(async () => {
            await api.v1.unarchiveRegistration(this.data?.id!!)
        }).finally(() => {
            this.archiving = false
        })

    }

    async archive() {
        this.archiving = true
        await catchApi(async () => {
            await api.v1.archiveRegistration(this.data?.id!!)
        }).finally(() => {
            this.archiving = false
        })
    }

    async startSignature() {
        await catchApi(async () => {
            await api.v1.sign({
                registrationId: this.id!!,
                signingProcessType: undefined
            })
        })

    }

    async triggerSocketRefresh(status: RegistrationStatusDto) {
        const modificationDateUnchanged = this.data?.mostRecentModificationDate === status.mostRecentModificationDate
        if (modificationDateUnchanged) {
            return
        }
        if (this.id && status.id === this.id) {
            if (!this.dirty) {
                await this.load()
            } else if (!this.loading && !this.saving) {
                growlStore.addMessage({
                    message: 'Es gibt Änderungen am Vorgang!',
                    action: async () => {
                        await this.load()
                    },
                    actionLabel: "Aktualisieren"
                })
            }
        }
    }

    async preSubmit() {
        const holder = this.holder
        await api.v1.triggerRegistrationOfficePortalAvailabilityCheck(holder.postCode!!, {
            correlationId: this.id!!,
            city: holder.city!!
        })
    }

    async submit() {
        const frontSealCode = this.data?.vehicleType === RegistrationDtoVehicleTypeEnum.CAR
            ? this.data?.previousLicensePlateSealFront || undefined : undefined

        await catchApi(async () => {
            await api.v1.submitRegistration(registrationStore.id!, {
                securityCode: this.data?.securityCode || undefined,
                verificationCode: this.data?.verificationCode || undefined,
                frontSealCode: frontSealCode,
                rearSealCode: this.data?.previousLicensePlateSealRear || undefined
            })
            await registrationStore.load()
        })

    }

    async validate() {
        const data = this.data
        if (data) {
            await registrationValidationStore.validate(data)
            registrationValidationStore.activated = true
        }
    }
}

const registrationStore = new RegistrationStore()

export type RegistrationStoreProp = keyof RegistrationStore

export {
    registrationStore
}
